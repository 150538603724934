import { Datagrid, ShowButton, TextField, Show, SimpleShowLayout, ReferenceField, DeleteWithConfirmButton, TopToolbar, BooleanField } from 'react-admin'
import {PermIdentity} from '@mui/icons-material';
import FilterTextInput from "../components/filter_textinput";
import ParsedDateTextField from "../components/parsed_date_textfield";
import PersonTextField from "../components/person_textfield";
import OrgTextField from '../components/org_textfield';
import IdAndNameTextField from '../components/id_and_name_textfield';
import MyUrlField from '../components/my_url_field';
export const PersonIcon = PermIdentity;


const werifyPointFilters = [
  <FilterTextInput source="nameLike" alwaysOn />,
];

const werifyPointGrid = 
  <Datagrid bulkActionButtons={false}>
    <TextField source='id' />
    <TextField source="name" />
    <ReferenceField source="personId" reference="Person" link="show">
      <PersonTextField source="id" />
    </ReferenceField>
    <ReferenceField source="ruleId" reference="Rule" link="show">
      <IdAndNameTextField source="id" />
    </ReferenceField>
    <MyUrlField source="fullUrl" sortable={false} />
    <BooleanField source="timer" />
    <ShowButton />
    <DeleteWithConfirmButton redirect={false} />
  </Datagrid>


function WerifyPointShow(){
  return (
    <Show actions={<TopToolbar><DeleteWithConfirmButton redirect="/Org" /></TopToolbar>}>
      <SimpleShowLayout>
        <TextField source='id' />
        <ReferenceField source="orgId" reference="Org" link="show">
          <OrgTextField source="id" />
        </ReferenceField>
        <ReferenceField source="personId" reference="Person" link="show">
          <PersonTextField source="id" />
        </ReferenceField>
        <ReferenceField source="ruleId" reference="Rule" link="show">
          <IdAndNameTextField source="id" />
        </ReferenceField>
        <TextField source="name" />
        <MyUrlField source="fullUrl" /> 
        <BooleanField source="timer" />
        <ReferenceField source="ruleId" reference="Rule" link="show" label="resources.Rule.fields.redirectUrl" >
          <TextField source="redirectUrl" />
        </ReferenceField>
        <ParsedDateTextField source='createdAt' />
        <ParsedDateTextField source='archivedAt' />
      </SimpleShowLayout>
    </Show>
  );
}


export {werifyPointFilters, werifyPointGrid, WerifyPointShow};
