import { SelectInput, required } from 'react-admin';

const restrictiveFilterKinds = [
  'Exists',
  'StringContains',
  'StringStarts',
  'StringEnds',
  'OlderThanYears',
  'YoungerThanYears',
  'DateAfter',
  'DateBefore',
  'NumberGreaterThan',
  'NumberLesserThan',
  'StringMatches',
  'ArrayContains',
];

const SelectFilter = ({source, validate, alwaysOn}: {source: string, validate?: any, alwaysOn?: boolean}) => {

  return <SelectInput source={source} validate={validate} alwaysOn={alwaysOn}
      choices={ restrictiveFilterKinds.map((v) => ({
        id: v,
        name: `resources.KnownAttribute.fields.filters.${v}`
      }))}
    />;
}
export default SelectFilter;
