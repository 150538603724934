import { List, Datagrid, TextField, Show, SimpleShowLayout, ShowButton, ReferenceField, Create, SimpleForm, required } from 'react-admin'
import { PaginationDefault } from "../components/utils";
import {TopToolbarWithCreateButton} from "../components/top_toolbars";
import FilterTextInput from "../components/filter_textinput";
import PersonTextField from "../components/person_textfield";
import MyUrlField from '../components/my_url_field';
import OrgTextField from '../components/org_textfield';
import SelectPersons from '../components/select_persons';

function OneTimeLoginList() {

  const oneTimeLoginFilters = [
    <FilterTextInput source="idEq" />,
    <FilterTextInput source="orgIdEq" />,
    <FilterTextInput source="personIdEq" />,
  ];

  return (
    <List
      empty={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={oneTimeLoginFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbarWithCreateButton/>}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source='id' />
        <ReferenceField source="personId" reference="Person" link="show">
          <PersonTextField source="id" />
        </ReferenceField>
        <ReferenceField source="orgId" reference="Org" link="show">
          <OrgTextField source="id" />
        </ReferenceField>
        <MyUrlField source="url" sortable={false} />
        <ShowButton />
      </Datagrid>
    </List>
  );
}

function OneTimeLoginShow(){

  return (
    <Show>
      <SimpleShowLayout >
        <TextField source='id' />
        <ReferenceField source="personId" reference="Person" link="show">
          <PersonTextField source="id" />
        </ReferenceField>
        <ReferenceField source="orgId" reference="Org" link="show">
          <OrgTextField source="id" />
        </ReferenceField>
        <MyUrlField source="url" sortable={false} />
      </SimpleShowLayout>
    </Show>
  );
};



const OneTimeLoginCreate = () => {
  return (
    <Create title='resources.OneTimeLogin.create' resource="OneTimeLogin" >
        <SimpleForm warnWhenUnsavedChanges>
          <SelectPersons source="personId" validate={required()} />
        </SimpleForm>
    </Create>
  );
}

export {OneTimeLoginList, OneTimeLoginShow, OneTimeLoginCreate};