import { SelectInput } from 'react-admin';

const SelectDeletionReason = ({source, validate, alwaysOn}: {source: string, validate?: any, alwaysOn?: boolean}) => {
  return(
  <SelectInput source={source} validate={validate} alwaysOn={alwaysOn} choices={[
    { id: 'UserRequest', name: "resources.OrgDeletion.fields.reasons.USER_REQUEST" },
    { id: 'ManagementDecision', name: "resources.OrgDeletion.fields.reasons.MANAGEMENT_DECISION" },
    { id: 'Inactivity', name: "resources.OrgDeletion.fields.reasons.INACTIVITY" },
  ]} />
)};

export default SelectDeletionReason;