import { List, Datagrid, TextField, BooleanField, ShowButton, Show, SimpleShowLayout, 
         ReferenceField } from 'react-admin'
import { PaginationDefault, defaultSort } from "../components/utils";
import {TopToolbarDefault} from "../components/top_toolbars";
import FilterTextInput from "../components/filter_textinput";
import OrgTextField from '../components/org_textfield';
import TranslatedTextField from '../components/translated_textfield';


function EmailList() {

  const emailFilters = [
    <FilterTextInput source="addressLike" alwaysOn />,
    <FilterTextInput source="personIdEq" />,
    <FilterTextInput source="orgIdEq" />,
  ];

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={emailFilters}
      filter={{archivedAtIsSet: false}}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbarDefault/>}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source='address'/>
        <ReferenceField source="orgId" reference="Org" link="show">
          <OrgTextField source="id" />
        </ReferenceField>
        <ReferenceField source="personId" reference="Person" link="show">
          <TextField source="id" />
        </ReferenceField>
        <ReferenceField source="personId" reference="Person" link="show" label="resources.Person.fields.role" sortable={false}>
          <TranslatedTextField source="role" translation="resources.Person.fields.roles" />
        </ReferenceField>
        <ShowButton />
      </Datagrid>
    </List>
  );
}


function EmailShow() {
  return (
    <Show>
      <SimpleShowLayout >
        <TextField source='address'/>
        <ReferenceField source="personId" reference="Person" link="show">
          <TextField source="id" />
        </ReferenceField>
        <ReferenceField source="orgId" reference="Org" link="show">
          <OrgTextField source="id" />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
}

export {EmailList, EmailShow};