import { 
  Datagrid, ShowButton, TextField, Show, SimpleShowLayout, ReferenceField,
  BooleanField, Create, SimpleForm, TextInput, required, TopToolbar, DeleteWithConfirmButton, Edit, EditButton, BooleanInput
} from 'react-admin'
import {PermIdentity} from '@mui/icons-material';
import FilterTextInput from "../components/filter_textinput";
import ParsedDateTextField from "../components/parsed_date_textfield";
import OrgTextField from '../components/org_textfield';
import { OnlySaveToolbar } from '../components/bottom_toolbars';
import { Container, Divider } from '@mui/material';
export const PersonIcon = PermIdentity;


const ruleFilters = [
  <FilterTextInput source="nameLike" alwaysOn />,
];

const ruleGrid = 
  <Datagrid bulkActionButtons={false}>
    <TextField source='id' />
    <TextField source="name" />
    <ParsedDateTextField source='createdAt' />
    <BooleanField source="singleUseCredentials" />
    <ShowButton />
    <EditButton />
    <DeleteWithConfirmButton redirect={false} />
  </Datagrid>


function RuleShow(){
  return (
    <Show actions={<TopToolbar><EditButton /><DeleteWithConfirmButton redirect="/Org"/></TopToolbar>}>
      <SimpleShowLayout>
        <TextField source='id' />
        <ReferenceField source="orgId" reference="Org" link="show">
          <OrgTextField source="id" />
        </ReferenceField>
        <TextField source="name" />
        <TextField source="stateNotes" />
        <TextField source="redirectUrl" />
        <BooleanField source="singleUseCredentials" />
        <ParsedDateTextField source='createdAt' />
        <TextField source="directives" />
      </SimpleShowLayout>
    </Show>
  );
}

const RuleCreate = () => {
  return (
    <Create title='resources.Rule.create' resource="Rule" redirect="show">
        <SimpleForm warnWhenUnsavedChanges>
          <TextInput source="orgId" disabled />
          <TextInput source='name' validate={required()} />
          <TextInput source="redirectUrl" />
          <BooleanInput source="singleUseCredentials" />
          <TextInput source='directives' validate={required()} fullWidth multiline minRows={5} autoComplete="off" />
        </SimpleForm>
    </Create>
  );
}

const RuleEdit = () => {
  return (
    <Edit redirect="show">
      <SimpleForm toolbar={<OnlySaveToolbar />} warnWhenUnsavedChanges>
        <Container sx={{margin: "1em"}}>
          <ReferenceField source="orgId" reference="Org" link="show">
            <OrgTextField source="id" />
          </ReferenceField>
          <Divider sx={{marginBottom: "1em"}} />
        <TextInput source="id" disabled />
        <TextInput source='name' validate={required()} />
        <TextInput source="redirectUrl" />
        <BooleanInput source="singleUseCredentials" />
        <TextInput source='directives' validate={required()} fullWidth multiline minRows={5} autoComplete="off" />
        </Container>
      </SimpleForm>
    </Edit>
  )
};

export {ruleFilters, ruleGrid, RuleShow, RuleCreate, RuleEdit};
