import { SelectInput, useGetList, useTranslate } from 'react-admin';
import type { Validator } from 'react-admin';

interface SelectTemplatesInterface {
  source: string,
  validate?: Validator | Validator[],
}

const SelectPersons = ({source, validate}: SelectTemplatesInterface) => {
    const translate = useTranslate();
    let loadingValue = [{id: "", name: translate("resources.Org.loading")}];
    const { data: choices, isLoading } = useGetList(
        "Person",
        {
            pagination: { page: 1, perPage: 100 },
            sort: {field: 'id', order: 'ASC'},
            filter: {}
        },
    );

  const optionRenderer = choice => choice.emailAddress ? `${choice.id} - ${choice.address}` : choice.id;

  return(
  <SelectInput
    source={source}
    validate={validate}
    choices={ isLoading ? loadingValue : choices }
    optionText={optionRenderer}
    translateChoice={false}
  />
)};

export default SelectPersons;