import {
  Datagrid, ShowButton, EditButton, TextField, TextInput, ReferenceField, required,
  Show, NumberField, Edit, SimpleForm, TopToolbar, SimpleShowLayout,
  DeleteWithConfirmButton, useNotify, useDataProvider, useRedirect, 
} from 'react-admin'
import { Box, Container, Divider } from '@mui/material';
import {OnlySaveToolbar} from "../components/bottom_toolbars";
import FilterTextInput from "../components/filter_textinput";
import OrgTextField from '../components/org_textfield';
import SelectFilter from '../components/select_filter';
import TranslatedTextField from '../components/translated_textfield';
import _ from "lodash";
import { GenericCreateForm } from './known_did';


const knownAttributeFilters = [
  <SelectFilter source="filterLike" alwaysOn={true} />,
  <FilterTextInput source="labelLike" alwaysOn />,
  <FilterTextInput source="pointerLike" alwaysOn />,
  <FilterTextInput source="credentialTypeLike" alwaysOn />,
];


const knownAttributeGrid =
  <Datagrid bulkActionButtons={false}>
    <TextField source='id' />
    <TextField source='label' />
    <TextField source='pointer' />
    <TranslatedTextField source="filter" translation="resources.KnownAttribute.fields.filters" />
    <TextField source='credentialType' />
    <ShowButton />
    <EditButton />
    <DeleteWithConfirmButton redirect={false} />
  </Datagrid>


function KnownAttributeShow(){

  return (
    <Show actions={<TopToolbar><EditButton /><DeleteWithConfirmButton redirect="/Org"/></TopToolbar>} >
      <SimpleShowLayout>
        <NumberField source='id' />
        <ReferenceField source="orgId" reference="Org" link="show">
          <OrgTextField source="id" />
        </ReferenceField>
        <TextField source='label' />
        <TextField source='pointer' />
        <TranslatedTextField source="filter" translation="resources.KnownAttribute.fields.filters" />
        <TextField source='credentialType' />
      </SimpleShowLayout>
    </Show>
  );
}

const KnownAttributeCreate = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const validateFields = fields => {
    return _.includes(fields, "label") &&
           _.includes(fields, "pointer") &&
           _.includes(fields, "filter") &&
           _.includes(fields, "credentialType");
  }


  const createKnownAttributes = async ({results, orgId}) => {
    try {
      for (const row of results.data) {
        await dataProvider.create("KnownAttribute", {
          data: {
            orgId,
            label: row.label,
            pointer: row.pointer,
            filter: row.filter,
            credentialType: row.credentialType,
          }
        });
      }
      redirect(`/Org/${orgId}/show/knownAttribute`);
    } catch (e) {
      notify(`Error: ${e}`, { type: 'error' });
    }
  }

  return (
    <GenericCreateForm
      createFunction={createKnownAttributes}
      validateFields={validateFields}
      resource="KnownAttribute"
      id="upload-known-attribute"
    >
      <Box display="flex" flexDirection="row" justifyContent="space-evenly" margin="1.5em">
        <TextInput source="label" autoComplete="off" validate={required()} />
        <TextInput source="pointer" autoComplete="off" validate={required()} />
        <SelectFilter source="filter" validate={required()} />
        <TextInput source="credentialType" autoComplete="off" />
      </Box>
    </GenericCreateForm>
  );
}



const KnownAttributeEdit = () => {
  return (
    <Edit redirect="show">
      <SimpleForm toolbar={<OnlySaveToolbar />} warnWhenUnsavedChanges>
        <TextInput disabled source="id" />
          <TextInput source="label" autoComplete="off" validate={required()} />
          <TextInput source="pointer" autoComplete="off" validate={required()} />
          <SelectFilter source="filter" validate={required()} />
          <TextInput source="credentialType" autoComplete="off" />
      </SimpleForm>
    </Edit>
  )
};

export {knownAttributeFilters, knownAttributeGrid, KnownAttributeShow, KnownAttributeCreate, KnownAttributeEdit};
