import { List, Datagrid, ShowButton, TextField, Show, SimpleShowLayout, ReferenceField, FunctionField, BooleanInput, BooleanField } from 'react-admin'
import { PaginationDefault, defaultSort } from "../components/utils";
import {PermIdentity} from '@mui/icons-material';
import FilterTextInput from "../components/filter_textinput";
import ParsedDateTextField from "../components/parsed_date_textfield";
import { TopToolbarDefault } from "../components/top_toolbars";
import OrgTextField from '../components/org_textfield';
import SelectOutgoingEmailMessageState from '../components/select_web_callback_state';
import TranslatedTextField from '../components/translated_textfield';
export const PersonIcon = PermIdentity;



function OutgoingEmailMessageList() {

  const outgoingEmailMessageFilters = [
    <FilterTextInput source="idEq" />,
    <FilterTextInput source="personIdEq" />,
    <FilterTextInput source="emailAddressIdEq" />,
    <BooleanInput source="sentAtIsSet" />,
  ];

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={outgoingEmailMessageFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbarDefault />}
    >
      {outgoingEmailMessageGrid}
    </List>
  );
}

const outgoingEmailMessageGrid = 
  <Datagrid bulkActionButtons={false}>
    <TextField source='id' />
    <ReferenceField source="orgId" reference="Org" link="show" sortable={false}>
      <OrgTextField source="id" />
    </ReferenceField>
    <ReferenceField source="personId" reference="Person" link="show">
      <OrgTextField source="id" />
    </ReferenceField>
    <ReferenceField source="emailAddressId" reference="EmailAddress" link="show">
      <OrgTextField source="id" />
    </ReferenceField>
    <ParsedDateTextField source='sentAt' />
    <TranslatedTextField source="kind" translation="resources.OutgoingEmailMessage.fields.kinds" />
    <ShowButton />
  </Datagrid>


function OutgoingEmailMessageShow(){
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source='id' />
        <ReferenceField source="orgId" reference="Org" link="show" sortable={false}>
          <OrgTextField source="id" />
        </ReferenceField>
        <ReferenceField source="personId" reference="Person" link="show">
          <OrgTextField source="id" />
        </ReferenceField>
        <ReferenceField source="emailAddressId" reference="EmailAddress" link="show">
          <OrgTextField source="id" />
        </ReferenceField>
        <ParsedDateTextField source='sentAt' />
        <TranslatedTextField source="kind" translation="resources.OutgoingEmailMessage.fields.kinds" />
        <TextField source="customMessage" />
        <TextField source="extraData" />
      </SimpleShowLayout>
    </Show>
  );
}


export {OutgoingEmailMessageList, OutgoingEmailMessageShow, outgoingEmailMessageGrid};
