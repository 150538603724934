import CSVFileInputGeneric from './csv_file_input_generic';
import { useDataProvider, useNotify, useTranslate } from 'react-admin';

function CSVFileInputKnownResources({ id, resource, setLoading, buttonLabel, handleCreateResources, orgId, validateFields }) {
  const notify = useNotify();
  const translate = useTranslate();
  const dataProvider = useDataProvider();

  const handleFileProcessing = async (results) => {
    const fields = results?.meta?.fields;
    if (!validateFields(fields)) {
      return notify('resources.actions.errorInvalidFieldsResource', { type: 'error' });
    }

    try {
      await handleCreateResources({ results, dataProvider, orgId });
    } catch (e) {
      notify(translate(`resources.actions.errorUpdatingResource`, { resource }), { type: 'error' });
    }
  };

  return (
    <CSVFileInputGeneric
      id={id}
      setLoading={setLoading}
      buttonLabel={buttonLabel}
      handleFileProcessing={handleFileProcessing}
      orgId={orgId}
    />
  );
}

export default CSVFileInputKnownResources;