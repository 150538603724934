import { useEffect } from 'react';
import { SelectInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';

const ROLES = [
    "ADMIN",
    "MANAGER",
    "AUDITOR",
    "OPERATOR",
    "AGENT",
    "REVOKED"
];

const SelectRoles = ({source, validate, showRevokedRole}: {source: string, validate?: any, showRevokedRole?: boolean}) => {
  let localRoles = [...ROLES];
  if (!showRevokedRole) { _.pull(localRoles, "REVOKED"); }
  const { watch, setValue } = useFormContext();
  const orgId = watch("orgId");

  useEffect(() => {
    if (!orgId) { setValue("maybeRole", "ADMIN") };
  }, [orgId, setValue]);

  const choices = localRoles.map((role) => ({
    id: role,
    name: `resources.Person.fields.roles.${role}`
  }))

  return(
    <SelectInput source={source} choices={choices} validate={validate} disabled={!orgId} defaultValue={"MANAGER"} />
)};

export default SelectRoles;
