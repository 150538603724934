import CSVFileInputGeneric from './csv_file_input_generic'; // Asegúrate de que esta es la ruta correcta al componente
import _ from "lodash";
import { useDataProvider, useNotify, useRedirect, useRefresh } from 'react-admin';

function CSVFileInputDidList({ id, setLoading, buttonLabel, handleGetIdsArray, orgId, name }) {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const createDidList = async (idsArray) => {
    try {
      await dataProvider.create("DidList", {
        data: { orgId, name, knownDidIds: idsArray }
       });
       redirect("/DidList");
       refresh();
    } catch (e) {
      notify(`Error: ${e}`, { type: 'error' });
    }
  }

  const handleFileProcessing = async (results) => {
    const fields = results?.meta?.fields;
    if (id === "upload-ids" && !_.includes(fields, "id") || id === "upload-values" && (!_.includes(fields, "did") || !_.includes(fields, "label"))) {
      notify(`resources.DidList.errorUpdating`, { type: 'error' });
      return;
    }

    try {
      const idsArray = await handleGetIdsArray(results);
      await createDidList(idsArray);
    } catch (e) {
      notify(`resources.DidList.errorUpdating`, { type: 'error' });
    }
  };

  return (
    <CSVFileInputGeneric
      id={id}
      setLoading={setLoading}
      buttonLabel={buttonLabel}
      handleFileProcessing={handleFileProcessing}
      orgId={orgId}
      additionalDisabledCondition={!name}
    />
  );
}

export default CSVFileInputDidList;