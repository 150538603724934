import { SelectInput } from 'react-admin';

const WEB_CALLBACK_STATES = [
  "DONE",
  "PENDING",
  "FAILED"
]

const SelectWebCallbackState = ({source, validate, alwaysOn}: {source: string, validate?: any, alwaysOn?: boolean}) => {
  const choices = WEB_CALLBACK_STATES.map((state) => ({
    id: state,
    name: `resources.WebCallback.fields.states.${state}`
  }));
  
  return <SelectInput source={source} validate={validate} alwaysOn={alwaysOn} choices={choices} />
};

export default SelectWebCallbackState;