import React from "react";
import { Create, SimpleForm, TextInput, PasswordInput, required, TextField, List, Datagrid, DeleteWithConfirmButton } from 'react-admin';
import { PaginationDefault, defaultSort } from "../components/utils";
import SelectAdminRole from "../components/select_admin_role";
import ParsedDateTextField from "../components/parsed_date_textfield";
import FilterTextInput from "../components/filter_textinput";
import { TopToolbarWithCreateButton } from "../components/top_toolbars";

function AdminUserList() {
  
  const adminUserFilters = [
    <FilterTextInput source="usernameLike" alwaysOn />,
    <SelectAdminRole source="roleEq" />
  ];
  
  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={adminUserFilters}
      filter={{archivedAtIsSet: false}}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbarWithCreateButton/>}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source='id' />
        <TextField source='username' />
        <TextField source='otpSeed' sortable={false} />
        <TextField source='role' />
        <ParsedDateTextField source='createdAt' />
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  );
}


function AdminUserCreate() {
  return (
    <Create title='resources.AdminUser.create'>
        <SimpleForm >
          <TextInput source='username' autoComplete="off" validate={required()}/>
          <PasswordInput source='password' autoComplete="off" validate={required()}/>
          <SelectAdminRole source="role" validate={required()}/>
        </SimpleForm>
    </Create>
  );
}

export {AdminUserList, AdminUserCreate};