import { List, Datagrid, ShowButton, TextField, Show, Tab, SimpleShowLayout, ReferenceField, FunctionField } from 'react-admin'
import { PaginationDefault, defaultSort } from "../components/utils";
import {PermIdentity} from '@mui/icons-material';
import FilterTextInput from "../components/filter_textinput";
import ParsedDateTextField from "../components/parsed_date_textfield";
import { TopToolbarDefault } from "../components/top_toolbars";
import OrgTextField from '../components/org_textfield';
import SelectAttemptState from '../components/select_attempt_state';
import TranslatedTextField from '../components/translated_textfield';
import IdAndNameTextField from '../components/id_and_name_textfield';
export const PersonIcon = PermIdentity;



function AttemptList() {

  const requestFilters = [
    <SelectAttemptState source="stateEq" alwaysOn={true} />,
    <FilterTextInput source="idEq" />,
    <FilterTextInput source="orgIdEq" />,
    <FilterTextInput source="werifyPointIdEq" />,
  ];

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={requestFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbarDefault />}
    >
      {requestGrid}
    </List>
  );
}

const requestGrid = 
  <Datagrid bulkActionButtons={false}>
    <TextField source='id' />
    <ReferenceField source="orgId" reference="Org" link="show">
      <OrgTextField source="id" />
    </ReferenceField>
    <ReferenceField source="werifyPointId" reference="WerifyPoint" link="show">
      <IdAndNameTextField source="id" />
    </ReferenceField>
    <ReferenceField source="ruleId" reference="Rule" link="show" sortable={false}>
      <IdAndNameTextField source="id" />
    </ReferenceField>
    <TranslatedTextField source="state" translation="resources.Attempt.fields.states" />
    <ParsedDateTextField source='finishedAt' />
    <ShowButton />
  </Datagrid>


function AttemptShow(){
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source='id' />
        <ReferenceField source="orgId" reference="Org" link="show">
          <OrgTextField source="id" />
        </ReferenceField>
        <ReferenceField source="werifyPointId" reference="WerifyPoint" link="show">
          <IdAndNameTextField source="id" />
        </ReferenceField>
        <ReferenceField source="ruleId" reference="Rule" link="show">
          <IdAndNameTextField source="id" />
        </ReferenceField>
        <TranslatedTextField source="state" translation="resources.Attempt.fields.states" />
        <ReferenceField source="webCallbackId" reference="WebCallback" link="show">
          <TranslatedTextField source="state" translation="resources.WebCallback.fields.states" />
        </ReferenceField>
        <TextField source="stateNotes" />
        <TextField source="did" />
        <ParsedDateTextField source='finishedAt' />
      </SimpleShowLayout>
    </Show>
  );
}


export {AttemptList, AttemptShow, requestGrid};
