import {
  List, Datagrid, ShowButton, TextField, TextInput, required, DeleteWithConfirmButton,
  ReferenceInput, useTranslate, AutocompleteInput, FunctionField, Show, SimpleForm, TopToolbar,
  Create, SimpleShowLayout, useSafeSetState, ArrayInput, SimpleFormIterator, ArrayField,
  ReferenceField, Form, useDataProvider, useNotify, useRefresh
} from 'react-admin'
import { Box, Button, Container, Divider } from '@mui/material';
import { PaginationDefault, defaultSort, downloadCSV } from "../components/utils";
import { TopToolbarWithCreateButton } from '../components/top_toolbars';
import FilterTextInput from "../components/filter_textinput";
import UpdateResourceAction from '../components/update_resource_action';
import { Add, Download   } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useWatch } from 'react-hook-form';
import _ from "lodash";
import OrgTextField from '../components/org_textfield';
import SelectOrgs from '../components/select_orgs';
import CSVFileInputDidList from '../components/csv_file_input_did_list';
import Loading from '../components/loading';



const didListFilters = [
  <FilterTextInput source="nameLike" alwaysOn />,
];

function DidListList() {

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={didListFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbarWithCreateButton />}
    >
     {didListDatagrid}
    </List>
  );
}

const didListDatagrid =  <Datagrid bulkActionButtons={false}>
  <ReferenceField source="orgId" reference="Org" link="show">
    <OrgTextField source="id" />
  </ReferenceField>
  <TextField source='name' />
  <TextField source='count' sortable={false} />
  <ShowButton />
  <DeleteWithConfirmButton redirect={false} />
</Datagrid>;
 

function DidListShow(){
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const { id } = useParams();

  const optionText = values => {
    return `${values.label} - ${values.did}`;
  }

  const handleSubmit = async (values: any) => {
    try {
      await dataProvider.update("DidList", {
        id, data: {action: "add", knownDidIds: [values.id]}, previousData: {}
      });
    } catch (e) {
      notify(`resources.DidList.errorUpdating`, { type: 'error' });
    }
    refresh();
  }

  const handleDownloadIds = knownDidIds => {
    downloadCSV(knownDidIds, "did_list_ids");
  };

  const getKnownDids = async (knownDidIds, perPage) => {
    return await dataProvider.getList('KnownDid', {
      sort: {field: "id", order: "ASC"}, pagination: { page: 1, perPage }, filter: {ids: knownDidIds}
    });
  }

  const handleDownloadValues = async knownDidIds => {
    try {
      const {total} = await getKnownDids(knownDidIds, 1);
      const {data} = await getKnownDids(knownDidIds, total);
      const finalDids = _.map(data, a => _.pick(a, ['label', 'did']));
      downloadCSV(finalDids, "did_list_values");
    } catch (e) {
      notify('admin.errors.default', {type: 'warning'});
    }
  };  

  return (
    <Show actions={<TopToolbar><DeleteWithConfirmButton /></TopToolbar>} >
      <SimpleShowLayout>
        <ReferenceField source="orgId" reference="Org" link="show">
          <OrgTextField source="id" />
        </ReferenceField>
        <TextField source='name' />
        <TextField source='count' />
        <FunctionField render={record => <Button startIcon={<Download />} id="download-ids" onClick={() => handleDownloadIds(record.knownDidIds)}>
          {translate("resources.DidList.downloadIds")}
          </Button>
        }/>
        <FunctionField render={record => <Button startIcon={<Download />} id="download-values" onClick={() => handleDownloadValues(record.knownDidIdsIds)}>
          {translate("resources.DidList.downloadValues")}
          </Button>
        }/>
      </SimpleShowLayout>
      <SimpleShowLayout>
        <ArrayField source='knownDidIds' >
          <Datagrid bulkActionButtons={false}>
            <ReferenceField source="id" label="resources.KnownDid.fields.label" reference="KnownDid">
              <TextField source="label" />
            </ReferenceField>
            <ReferenceField source="id" label="resources.KnownDid.fields.did" reference="KnownDid">
              <TextField source="did" />
            </ReferenceField>
            <ReferenceField source="id" label="" reference="KnownDid" link={false}>
              <FunctionField render={record => <UpdateResourceAction resource="DidList" data={{action: "delete", knownDidIds: [record.id]}} />} />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
      <SimpleShowLayout >
        <FunctionField render={record => {
          return <Form onSubmit={handleSubmit} noValidate id="add-known-did" >
            <Box display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" width="700px">
              <ReferenceInput source="id" reference="KnownDid" validate={required()} fullWidth filter={{idsNot: record.knownDidIdsIds, orgIdEq: record.orgId}} >
                <AutocompleteInput optionText={optionText} fullWidth/>
              </ReferenceInput>
              <Button sx={{mx: "1em", px: "2em"}} startIcon={<Add />} color="info" variant="outlined" size="small" type="submit">
                {translate("resources.DidList.add_to_list")}
              </Button>
            </Box>    
            </Form> 
        }}
        />
      </SimpleShowLayout>
    </Show>
  );
}


const DidListCreate = () => {
  const [dids, setDids] = useSafeSetState([]);
  const [orgId, setOrgId] = useSafeSetState(null);
  const [name, setName] = useSafeSetState(null);
  const [filter, setFilter] = useSafeSetState({});
  const [loading, setLoading] = useSafeSetState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const translate = useTranslate();

  const WatchDids = () => {
    const knownDidIds = useWatch({ name: 'knownDidIds' });
    const watchOrgId = useWatch({ name: 'orgId' });
    const watchName = useWatch({ name: 'name' });
    orgId !== watchOrgId && setOrgId(watchOrgId);
    name !== watchName && setName(watchName);
    if (!_.isEqual(knownDidIds, dids)) {
      setDids(knownDidIds);
      const idsNot = _.filter(knownDidIds, v => v.id !== null).map(e => e.id);
      setFilter({idsNot, orgIdEq: watchOrgId});
    }
    return null;
  };

  const transform = values => {
    values.knownDidIds = values.knownDidIds.map(e => e.id);
    return values;
  }

  const optionText = values => {
    return `${values.label} - ${values.did}`;
  }

  const getIdsFromCsv = async results => results.data.map(row => _.toNumber(row.id)).filter(id => !isNaN(id) && id !== 0);

  const createKnownDidsAndGetIds = async results => {
    try {
      let labelDidArray = [];
      for (const row of results.data) {
        const { data } = await dataProvider.create("KnownDid", {
          data: { orgId, did: row.did, label: row.label }
        });
        labelDidArray.push(data.id);
      }
      return labelDidArray;
    } catch (e) {
      notify(`Error: ${e}`, { type: 'error' });
    }
  }

  if (loading) return <Loading />

  return (
    <Create resource="DidList" redirect="show" transform={transform}>
      <SimpleForm>
        <Container>
          <SelectOrgs source="orgId" validate={required()} />
          <TextInput source="name" autoComplete="off" validate={required()} />
        
          <Divider>{translate("resources.DidList.uploadFromFile")}</Divider> 
          <Box display="flex" flexDirection="row" justifyContent="space-evenly" margin="1.5em">
            <CSVFileInputDidList
              id="upload-ids"
              setLoading={setLoading}
              buttonLabel="resources.DidList.createByDids"
              handleGetIdsArray={getIdsFromCsv}
              orgId={orgId}
              name={name}
            />
            <CSVFileInputDidList
              id="upload-values"
              setLoading={setLoading}
              buttonLabel="resources.DidList.createByValues"
              handleGetIdsArray={createKnownDidsAndGetIds}
              orgId={orgId}
              name={name} 
            />
          </Box>
          <Divider>{translate("resources.DidList.selectManually")}</Divider>
        
          <ArrayInput source="knownDidIds" validate={required()} disabled={!orgId}>
            <SimpleFormIterator fullWidth disableReordering>
              <ReferenceInput source="id" reference="KnownDid" filter={filter}>
                <AutocompleteInput label="resources.DidList.fields.id" optionText={optionText} validate={required()} fullWidth />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
      
          <WatchDids />
        </Container>
      </SimpleForm>
    </Create>
  );
}



export {DidListList, DidListShow, DidListCreate, didListDatagrid, didListFilters};
