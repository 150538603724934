import { TextInput } from 'react-admin';

const FilterTextInput = (
  {source, label, alwaysOn, onChange}:
  {source: string, label?: string, alwaysOn?: boolean, onChange?: any }
) => {
  return(
  <TextInput
    source={source}
    label={label}
    alwaysOn={alwaysOn}
    onChange={onChange}
    autoComplete="off"
  />
)};

export default FilterTextInput;