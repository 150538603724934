import {useEffect} from "react";
import { List, Datagrid, ShowButton, TextField, FunctionField, Show, NumberField, Create, SimpleForm, email, TopToolbar,
         SimpleShowLayout, useTranslate, ReferenceField, required, TextInput, DeleteWithConfirmButton, Edit, EditButton,
} from 'react-admin'
import { useFormContext } from 'react-hook-form';
import { PaginationDefault, defaultSort } from "../components/utils";
import {PermIdentity} from '@mui/icons-material';
import FilterTextInput from "../components/filter_textinput";
import { FieldCopyWithUrl } from "../components/copy_to_clipboard";
import ParsedDateTextField from "../components/parsed_date_textfield";
import { TopToolbarWithCreateButton } from "../components/top_toolbars";
import SelectLang from '../components/select_lang';
import SelectOrgs from '../components/select_orgs';
import OrgTextField from '../components/org_textfield';
import TranslatedTextField from '../components/translated_textfield';
import SelectRoles from '../components/select_roles';
import { OnlySaveToolbar } from '../components/bottom_toolbars';
import SelectWallet from "../components/select_wallet";
export const PersonIcon = PermIdentity;


function PersonList() {

  const userFilters = [
    <FilterTextInput source="idEq" />,
    <FilterTextInput source="orgIdEq" />,
  ];

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={userFilters}
      filter={{archivedAtIsSet: false}}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbarWithCreateButton />}
    >
      {personGrid}
    </List>
  );
}

const personGrid = 
  <Datagrid bulkActionButtons={false}>
    <TextField source='id' />
    <ReferenceField source="orgId" reference="Org" link="show">
      <OrgTextField source="id" />
    </ReferenceField>
    <ReferenceField source="emailAddress" reference="EmailAddress" link="show" sortable={false}>
      <TextField source="address" />
    </ReferenceField>
    <TranslatedTextField source="lang" translation="resources.Person.fields.langs" />
    <TranslatedTextField source="role" translation="resources.Person.fields.roles" />
    <FunctionField source="termsUrl" sortable={false} render={record => <TermsField record={record} />} />
    <ShowButton />
    <EditButton />
    <DeleteWithConfirmButton redirect={false} />
  </Datagrid>


function PersonShow(){
  return (
    <Show actions={<TopToolbar><EditButton /><DeleteWithConfirmButton /></TopToolbar>}>
      <SimpleShowLayout>
        <NumberField source='id' />
        <ReferenceField source="orgId" reference="Org" link="show">
          <OrgTextField source="id" />
        </ReferenceField>
        <ReferenceField source="emailAddress" reference="EmailAddress" link="show">
          <TextField source="address" />
        </ReferenceField>
        <TextField source="fullName" />
        <TranslatedTextField source="lang" translation="resources.Person.fields.langs" />
        <TranslatedTextField source="role" translation="resources.Person.fields.roles" />
        <ParsedDateTextField source='registrationDate' />
        <FunctionField source="termsUrl" render={record => <TermsField record={record} />} />
      </SimpleShowLayout>
    </Show>
  );
}

const TermsField = ({ record }) => {
  const translate = useTranslate();
  if (record.isTermsAccepted) {
    return <>{translate("resources.Person.fields.already_accepted")}</>;
  }

  return (
    <FieldCopyWithUrl
      text={translate("resources.Person.fields.not_accepted_yet")}
      url={record.termsUrl}
    />
  );
};

const PersonCreate = () => {
  return (
    <Create title='resources.Person.create' resource="Person" redirect="show">
      <SimpleForm warnWhenUnsavedChanges>
        <SelectOrgs source="orgId" />
        <TextInput source='fullName' validate={required()} />
        <TextInput source='emailAddress' validate={[required(), email()]} />
        <SelectLang source="lang" />
        <SelectRoles source="maybeRole" validate={[required()]} />
        <NestedOrgFields />
      </SimpleForm>
    </Create>
  );
}

const NestedOrgFields = () => {
  const { watch, setValue } = useFormContext();
  const orgId = watch("orgId");
  const wallet = watch("wallet");

  useEffect(() => {
    if (!!orgId) {
      setValue("publicName", "")
      setValue("wallet", null)
      setValue("clientId", "")
      setValue("vidconnectClientId", "")
      setValue("vidconnectClientSecret", "")
    };
  }, [orgId, setValue]);

  return (<>
    <TextInput source='clientId' validate={!orgId && [required()]} disabled={!!orgId} />
    <TextInput source='publicName' validate={!orgId && [required()]} disabled={!!orgId} />
    <SelectWallet source="wallet" validate={!orgId && [required()]} disabled={!!orgId} />
    <TextInput source='vidconnectClientId' validate={(!orgId && wallet === "VIDWALLET") && [required()]} disabled={!!orgId} />
    <TextInput source='vidconnectClientSecret' validate={(!orgId && wallet === "VIDWALLET") && [required()]} disabled={!!orgId} />
  </>);
};


const PersonEdit = () => {
  return (
    <Edit redirect="show">
      <SimpleForm toolbar={<OnlySaveToolbar />} warnWhenUnsavedChanges>
        <TextInput disabled source="id" />
        <TextInput source='fullName' validate={required()} />
        <SelectLang source="lang" />
        <SelectRoles source="role" showRevokedRole={true} validate={[required()]} />
      </SimpleForm>
    </Edit>
  )
};


export {PersonList, PersonShow, PersonCreate, PersonEdit, personGrid};
