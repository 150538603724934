import { SelectInput, required } from 'react-admin';

const WALLETS = [
  'VIDWALLET',
  'ALTME'
];

const SelectWallet = ({source, validate, disabled}: {source: string, validate: any, disabled?: boolean}) => {
  const choices = WALLETS.map((lang) => ({
    id: lang,
    name: `resources.Org.fields.wallets.${lang}`
  }))

  return(
    <SelectInput source={source} choices={choices} validate={validate} disabled={disabled} />
)};

export default SelectWallet;