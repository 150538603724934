import {
  BooleanField, BooleanInput, Datagrid, ShowButton, EditButton, TextField, TextInput, ReferenceField,
  Show, NumberField, Edit, SimpleForm, TopToolbar, SimpleShowLayout, required,
  DeleteWithConfirmButton, useNotify, useDataProvider, useRedirect,
} from 'react-admin';
import { Box } from '@mui/material';
import {OnlySaveToolbar} from "../components/bottom_toolbars";
import FilterTextInput from "../components/filter_textinput";
import OrgTextField from '../components/org_textfield';
import _ from "lodash";
import { GenericCreateForm } from './known_did';


const knownCredentialTypeFilters = [
  <FilterTextInput source="valueLike" alwaysOn />,
  <FilterTextInput source="helperLike" alwaysOn />,
];


const knownCredentialTypeGrid = 
  <Datagrid bulkActionButtons={false}>
    <TextField source='id' />
    <TextField source='value' />
    <TextField source='helper' />
    <BooleanField source="enabledInVidconnect" />
    <ShowButton />
    <EditButton />
    <DeleteWithConfirmButton redirect={false} />
  </Datagrid>


function KnownCredentialTypeShow(){

  return (
    <Show actions={<TopToolbar><EditButton /><DeleteWithConfirmButton redirect="/Org"/></TopToolbar>} >
      <SimpleShowLayout>
        <NumberField source='id' />
        <ReferenceField source="orgId" reference="Org" link="show">
          <OrgTextField source="id" />
        </ReferenceField>
        <TextField source='value' />
        <TextField source='helper' />
        <TextField source='enabledInVidconnect' />
      </SimpleShowLayout>
    </Show>
  );
}


const KnownCredentialTypeCreate = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const validateFields = fields => {
    return _.includes(fields, "value") && _.includes(fields, "helper") && _.includes(fields, "enabledInVidconnect");
  }

  const createKnownCredentialTypes = async ({results, orgId}) => {
    try {
      for (const row of results.data) {
        await dataProvider.create("KnownCredentialType", {
          data: {
            orgId,
            value: row.value,
            helper: row.helper,
            enabledInVidconnect: row.enabledInVidconnect
          }
        });
      }
      redirect(`/Org/${orgId}/show/knownCredentialType`);
    } catch (e) {
      notify(`Error: ${e}`, { type: 'error' });
    }
  }

  return (
    <GenericCreateForm
      createFunction={createKnownCredentialTypes}
      validateFields={validateFields}
      resource="KnownCredentialType"
      id="upload-known-credential-types"
    >
      <Box display="flex" flexDirection="row" justifyContent="space-evenly" margin="1.5em">
        <TextInput source="value" autoComplete="off" validate={required()} />
        <TextInput source="helper" autoComplete="off" validate={required()} />
        <BooleanInput source="enabledInVidconnect"/>
      </Box>
    </GenericCreateForm>
  );
}



const KnownCredentialTypeEdit = () => {
  return (
    <Edit redirect="show">
      <SimpleForm toolbar={<OnlySaveToolbar />} warnWhenUnsavedChanges>
        <TextInput disabled source="id" />
          <TextInput source="value" autoComplete="off" validate={required()} />
          <TextInput source="helper" autoComplete="off" validate={required()} />
          <BooleanInput source="enabledInVidconnect"/>
      </SimpleForm>
    </Edit>
  )
};

export {knownCredentialTypeFilters, knownCredentialTypeGrid, KnownCredentialTypeShow, KnownCredentialTypeCreate, KnownCredentialTypeEdit};
