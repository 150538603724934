import { TopToolbar, FilterButton, CreateButton, EditButton } from 'react-admin'

const TopToolbarDefault = (props) => (
  <TopToolbar>
    <FilterButton/>
  </TopToolbar>
);

const TopToolbarWithCreateButton = () => {
  return (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
  </TopToolbar> 
)};


export {TopToolbarDefault, TopToolbarWithCreateButton};