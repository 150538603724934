import { List, Datagrid, ShowButton, TextField, Show, SimpleShowLayout, ReferenceField, FunctionField } from 'react-admin'
import { PaginationDefault, defaultSort } from "../components/utils";
import {PermIdentity} from '@mui/icons-material';
import FilterTextInput from "../components/filter_textinput";
import ParsedDateTextField from "../components/parsed_date_textfield";
import { TopToolbarDefault } from "../components/top_toolbars";
import OrgTextField from '../components/org_textfield';
import SelectWebCallbackState from '../components/select_web_callback_state';
import TranslatedTextField from '../components/translated_textfield';
export const PersonIcon = PermIdentity;



function WebCallbackList() {

  const webCallbackFilters = [
    <SelectWebCallbackState source="stateEq" alwaysOn={true} />,
    <FilterTextInput source="idEq" />,
    <FilterTextInput source="orgIdEq" />,
    <FilterTextInput source="resourceIdEq" />,
  ];

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={webCallbackFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbarDefault />}
    >
      {webCallbackGrid}
    </List>
  );
}

const webCallbackGrid = 
  <Datagrid bulkActionButtons={false}>
    <TextField source='id' />
    <ReferenceField source="orgId" reference="Org" link="show">
      <OrgTextField source="id" />
    </ReferenceField>
    <ReferenceField source="resourceId" reference="Attempt" link="show">
      <TextField source="id" />
    </ReferenceField>
    <TranslatedTextField source="state" translation="resources.WebCallback.fields.states" />
    <ParsedDateTextField source='createdAt' />
    <FunctionField source="nextAttemptOn" render={record => {
        if (record.state === "PENDING") return <ParsedDateTextField source='nextAttemptOn' />;
      }}
    />
    <TextField source="attemptsCount" sortable={false} />
    <ShowButton />
  </Datagrid>


function WebCallbackShow(){
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source='id' />
        <ReferenceField source="orgId" reference="Org" link="show">
          <OrgTextField source="id" />
        </ReferenceField>
        <ReferenceField source="resourceId" reference="Attempt" link="show">
          <TextField source="id" />
        </ReferenceField>
        <TranslatedTextField source="state" translation="resources.WebCallback.fields.states" />
        <ParsedDateTextField source='createdAt' />
        <ParsedDateTextField source='nextAttemptOn' />
        <TextField source="attemptsCount"/>
      </SimpleShowLayout>
    </Show>
  );
}


export {WebCallbackList, WebCallbackShow, webCallbackGrid};
