import {
  List, Datagrid, ShowButton, TextField, TextInput, required, DeleteWithConfirmButton,
  useTranslate, FunctionField, Show, SimpleForm, TopToolbar, SimpleFormIterator, ArrayField,
  Create, SimpleShowLayout, useSafeSetState, ArrayInput, useDataProvider, useNotify, useRefresh,
  ReferenceField, Form, useRedirect,
} from 'react-admin'
import { Box, Button, Container, Divider } from '@mui/material';
import { PaginationDefault, defaultSort, downloadCSV } from "../components/utils";
import { TopToolbarWithCreateButton } from '../components/top_toolbars';
import FilterTextInput from "../components/filter_textinput";
import UpdateResourceAction from '../components/update_resource_action';
import { Add, Download   } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useWatch } from 'react-hook-form';
import _ from "lodash";
import OrgTextField from '../components/org_textfield';
import SelectOrgs from '../components/select_orgs';
import CSVFileInputAttributeList from '../components/csv_file_input_attribute_list';
import Loading from '../components/loading';



const attributeListFilters = [
  <FilterTextInput source="labelLike" alwaysOn />,
  <FilterTextInput source="pointerLike" alwaysOn />,
];

function AttributeListList() {

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={attributeListFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbarWithCreateButton />}
    >
     {attributeListDatagrid}
    </List>
  );
}

const attributeListDatagrid =  <Datagrid bulkActionButtons={false}>
  <ReferenceField source="orgId" reference="Org" link="show">
    <OrgTextField source="id" />
  </ReferenceField>
  <TextField source='label' />
  <TextField source='pointer' />
  <TextField source='credentialType' />
  <TextField source='count' sortable={false} />
  <ShowButton />
  <DeleteWithConfirmButton redirect={false} />
</Datagrid>;
 

function AttributeListShow(){
  const [loading, setLoading] = useSafeSetState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const { id } = useParams();

  const handleSubmit = async (values: any) => {
    try {
      await dataProvider.update("AttributeList", {
        id, data: {action: "add", attributeValues: [values.value]}, previousData: {}
      });
    } catch (e) {
      notify(`resources.AttributeList.errorUpdating`, { type: 'error' });
    }
    refresh();
  }

  const handleDownloadValues = async attributeListId => {
    try {
      const {data: attributeList} = await dataProvider.getOne('AttributeList', {id: attributeListId});
      const finalAttributes = _.map(attributeList.values, a => _.pick(a, ['value']));
      downloadCSV(finalAttributes, "attribute_list_values");
    } catch (e) {
      notify('admin.errors.default', {type: 'warning'});
    }
  };

  const mutationFunction = async valuesArray => {
    await dataProvider.update("AttributeList", {
      id,
      data: { action: "add", attributeValues: valuesArray },
      previousData: {}
     });
  }

  if (loading) return <Loading />
  
  return (
    <Show actions={<TopToolbar><DeleteWithConfirmButton /></TopToolbar>} >
      <SimpleShowLayout>
        <ReferenceField source="orgId" reference="Org" link="show">
          <OrgTextField source="id" />
        </ReferenceField>
        <TextField source='label' />
        <TextField source='pointer' />
        <TextField source='credentialType' />
        <TextField source='count' />
        <FunctionField render={record => <Button startIcon={<Download />} id="download-values" onClick={() => handleDownloadValues(record.id)}>
          {translate("resources.DidList.downloadValues")}
          </Button>
        }/>
        <FunctionField render={record =>  <CSVFileInputAttributeList
            id="add-values"
            setLoading={setLoading}
            buttonLabel="resources.AttributeList.updateValues"
            orgId={record.orgId}
            disabled={false}
            mutationFunction={mutationFunction}
          />
        }/>
      </SimpleShowLayout>
      <SimpleShowLayout>
        <ArrayField source='values' >
          <Datagrid bulkActionButtons={false}>
            <TextField source="value" />
            <FunctionField render={record => <UpdateResourceAction resource="AttributeList" data={{action: "delete", attributeValues: [record.value]}} />} />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
      <SimpleShowLayout >
        <FunctionField render={record => {
          return <Form onSubmit={handleSubmit} noValidate id="add-value-to-attribute-list" >
            <Box display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" width="700px">
              <TextInput source="value" validate={required()} fullWidth />
              <Button sx={{mx: "1em", px: "2em"}} startIcon={<Add />} color="info" variant="outlined" size="small" type="submit">
                {translate("resources.DidList.add_to_list")}
              </Button>
            </Box>    
            </Form> 
        }}
        />
      </SimpleShowLayout>
    </Show>
  );
}


const AttributeListCreate = () => {
  const [attributeValues, setAttributeValues] = useSafeSetState([]);
  const [orgId, setOrgId] = useSafeSetState(null);
  const [label, setLabel] = useSafeSetState(null);
  const [pointer, setPointer] = useSafeSetState(null);
  const [credentialType, setCredentialType] = useSafeSetState(null);
  const [loading, setLoading] = useSafeSetState(false);
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  const WatchValues = () => {
    const attributesV = useWatch({ name: 'attributeValues' });
    const watchOrgId = useWatch({ name: 'orgId' });
    const watchLabel = useWatch({ name: 'label' });
    const watchPointer = useWatch({ name: 'pointer' });
    const watchCredentialType = useWatch({ name: 'credentialType' });
    orgId !== watchOrgId && setOrgId(watchOrgId);
    label !== watchLabel && setLabel(watchLabel);
    pointer !== watchPointer && setPointer(watchPointer);
    credentialType !== watchCredentialType && setCredentialType(watchCredentialType);
    if (!_.isEqual(attributesV, attributeValues)) {
      setAttributeValues(attributesV);
    }
    return null;
  };

  const transform = values => {
    values.attributeValues = values.attributeValues.map(e => e.attributeValue);
    return values;
  }

  const mutationFunction = async valuesArray => {
    await dataProvider.create("AttributeList", {
      data: { orgId, label, pointer, credentialType, attributeValues: valuesArray }
     });
     redirect("/AttributeList");
  }

  if (loading) return <Loading />

  return (
    <Create resource="AttributeList" redirect="show" transform={transform}>
      <SimpleForm>
        <Container>
          <SelectOrgs source="orgId" validate={required()} />
          <TextInput source="label" autoComplete="off" validate={required()} />
          <TextInput source="pointer" autoComplete="off" validate={required()} />
          <TextInput source="credentialType" autoComplete="off" />
          
          <Divider>{translate("resources.DidList.uploadFromFile")}</Divider> 
          <Box display="flex" flexDirection="row" justifyContent="space-evenly" margin="1.5em">
            <CSVFileInputAttributeList
              id="upload-values"
              setLoading={setLoading}
              buttonLabel="resources.DidList.createByValues"
              orgId={orgId}
              disabled={!label || !pointer}
              mutationFunction={mutationFunction}
            />
          </Box>
          <Divider>{translate("resources.DidList.selectManually")}</Divider>
        
          <ArrayInput source="attributeValues" validate={required()}>
            <SimpleFormIterator fullWidth disableReordering>
              <TextInput source="attributeValue" />
            </SimpleFormIterator>
          </ArrayInput>
          <WatchValues />
        </Container>
      </SimpleForm>
    </Create>
  );
}



export {AttributeListList, AttributeListShow, AttributeListCreate, attributeListDatagrid, attributeListFilters};
