import { List, Datagrid, ShowButton, TextField, FunctionField, Show, NumberField, Create, SimpleForm, SelectInput,
    TopToolbar, SimpleShowLayout, useTranslate, ReferenceField, BooleanField, required, TextInput,
    email } from 'react-admin'
import { PaginationDefault, defaultSort } from "../components/utils";
import {PermIdentity} from '@mui/icons-material';
import FilterTextInput from "../components/filter_textinput";
import PersonTextField from '../components/person_textfield';
import OrgTextField from '../components/org_textfield';
import { TopToolbarDefault } from '../components/top_toolbars';
import ParsedDateTextField from '../components/parsed_date_textfield';
export const PersonIcon = PermIdentity;


function SessionList() {

  const sessionFilters = [
  <FilterTextInput source="orgIdEq" />,
  <FilterTextInput source="personIdEq" />,
  ];

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={sessionFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbarDefault />}
    >
      {sessionGrid}
    </List>
  );
}

const sessionGrid = 
  <Datagrid bulkActionButtons={false}>
    <ReferenceField source="orgId" reference="Org" link="show">
      <OrgTextField source="id" />
    </ReferenceField>
    <ReferenceField source="personId" reference="Person" link="show">
      <PersonTextField source="id" />
    </ReferenceField>
    <TextField source='id' />
    <ParsedDateTextField source='createdAt' />
    <ShowButton />
  </Datagrid>


function SessionShow(){

return (
<Show>
 <SimpleShowLayout>
  <ReferenceField source="orgId" reference="Org" link="show">
    <OrgTextField source="id" />
  </ReferenceField>
  <ReferenceField source="personId" reference="Person" link="show">
    <PersonTextField source="id" />
  </ReferenceField>
  <TextField source='id' />
  <ParsedDateTextField source='createdAt' />
 </SimpleShowLayout>
</Show>
);
}




export {SessionList, SessionShow};
